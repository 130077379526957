import React, { useEffect, useState } from "react";
import { useLoad } from "../../context";
import ApiServices from "../../services/apiServices";
import { TextField, InputLabel, Divider, Modal, Box } from "@mui/material";
import LogoMetilife from "../../assets/images/pngs/logoMetilife.png";
import "./index.css";
import { useNavigate } from "react-router/dist";
import { toast } from "react-toastify";
import Mask from "../../services/mask";
import storage from "../../services/storage";
import Linkedin from "../../assets/images/svgs/linkedin.svg";
import Facebook from "../../assets/images/svgs/facebook.svg";
import Instagram from "../../assets/images/svgs/instagram.svg";
import Youtube from "../../assets/images/svgs/youtube.svg";
import Twitter from "../../assets/images/svgs/twitter.svg";
import CloseIcon from "@mui/icons-material/Close";

export default function Login() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();

  const [logar, setLogar] = useState({
    userName: "",
    password: "",
  });
  const [redefinirSenha, setRedefinirSenha] = useState({
    userId: "",
    token: "",
    senha: "",
    confirmarSenha: "",
  });
  const [recuperarSenha, setRecuperaSenha] = useState("");
  const [modalesqueciSenha, setModalesqueciSenha] = useState(false);
  const [modalRedefinirSenha, setModalRedefinirSenha] = useState(false);
  let rotaUrl = window.location.href;

  async function fnLogin(objEnvio) {
    objEnvio = objEnvio || {
      userName: logar.userName,
      password: logar.password,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost("login", objEnvio);
      if (response.statusCode == 200) {
        storage.set("TOKEN", response.data);
        navigate("/home");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnRecuperarSenha() {
    var objEnvio = { email: recuperarSenha };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/esqueci-minha-senha",
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success(response.data);
        setModalesqueciSenha(false);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnRedefinirSenha() {
    if (!validaSenha(redefinirSenha.senha)) {
      toast.error("A senha não atende aos critérios");
      return;
    }
    if (redefinirSenha.senha !== redefinirSenha.confirmarSenha) {
      toast.error("A senha e a confirmação de senha não coincidem");
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("UserName");
    const token = urlParams.get("token");
    let login = { userName: userName, password: redefinirSenha.senha };
    var objEnvio = {
      userName: userName || "",
      token: token || "",
      senha: redefinirSenha.senha,
      confirmarSenha: redefinirSenha.confirmarSenha,
    };

    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/confirmar-troca-senha",
        objEnvio
      );
      if (response.statusCode === 200) {
        fnLogin(login);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const validaEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validaSenha = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSpecialChar = /[!@#$%]/.test(password);

    return password.length >= minLength && hasLetter && hasSpecialChar;
  };
  useEffect(() => {
    if (rotaUrl.includes("redefinir-senha")) {
      setModalRedefinirSenha(true);
    } else {
      setModalRedefinirSenha(false);
    }
  }, [rotaUrl]);

  return (
    <>
      <div className="login">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 loginConteudo d-flex flex-column justify-content-center align-items-center loginMobile">
            <div className="col-6">
              <div className="my-3">
                <img className="logoMetilife" src={LogoMetilife} />
              </div>
              <div className="my-3 mt-5">
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Login"
                  className="inputLogin"
                  value={logar.userName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    e.persist();
                    setLogar((prev) => ({
                      ...prev,
                      userName: e.target.value,
                    }));
                  }}
                  fullWidth
                  size="small"
                />
              </div>
              <div className="mt-5">
                <TextField
                  className="inputLogin"
                  id="demo-helper-text-misaligned"
                  label="Senha"
                  type={"password"}
                  value={logar.password}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    e.persist();
                    setLogar((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  fullWidth
                  size="small"
                />
              </div>

              <div className="text-end mt-2">
                <a
                  className="pointer"
                  onClick={() => {
                    setModalesqueciSenha(true);
                  }}
                  style={{
                    color: "#A3CD4D",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Esqueci a senha
                </a>
              </div>
              <div className="mt-5 d-flex flex-column align-items-center">
                <button
                  className="btn btn-login "
                  onClick={() => {
                    fnLogin();
                  }}
                >
                  ENTRAR
                </button>
              </div>
            </div>

            <div
              className="text-center  w-100 mb-3"
              style={{
                marginTop: "40px",
              }}
            >
              <hr style={{ color: "#949FAC" }} />
              <div style={{ paddingTop: "20px" }}>
                <span className="textoMetilife">
                  © 2023 Metropolitan Life Seguros e Previdência Privada S.A -
                  Todos os direitos reservados.
                </span>
                <div className="mt-4">
                  <img src={Linkedin} alt="icone linkedin" className="me-4" />
                  <img src={Facebook} alt="icone facebook" className="me-4" />
                  <img src={Instagram} alt="icone instagram" className="me-4" />
                  <img src={Twitter} alt="icone instagram" className="me-4" />
                  <img src={Youtube} alt="icone youtube" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 imgLogin imgLoginNone p-0"></div>
        </div>
      </div>

      {/*///////////////////// ESQUECI SENHA MODAL ///////////////////// */}

      {modalesqueciSenha && (
        <Modal keepMounted open={modalesqueciSenha}>
          <Box className="modais d-flex justify-content-center align-items-center">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModalesqueciSenha(false);
                setRecuperaSenha("");
              }}
              style={{ fontSize: 30 }}
            />

            <div className="primeiroAcessoConteudo">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-12  d-flex justify-content-center align-items-center">
                  <div className="col-12">
                    <h3>Esqueci minha senha</h3>
                    <div className="mb-5">
                      <div className="esqueciParagrafo mt-3">
                        Insira seu e-mail cadastrado e enviaremos um link
                      </div>
                      <div className="esqueciParagrafo">
                        para redefinição de senha.
                      </div>
                    </div>
                    <div className="my-3">
                      <TextField
                        id="demo-helper-text-misaligned"
                        label="E-mail"
                        className="inputLogin"
                        value={recuperarSenha}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          e.persist();
                          setRecuperaSenha(e.target.value);
                        }}
                        placeholder="Informe seu e-mail"
                        fullWidth
                        size="small"
                      />
                    </div>

                    <div className="mt-4 d-flex justify-content-center">
                      <button className="btn-modal" onClick={fnRecuperarSenha}>
                        ENVIAR LINK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}

      {/*///////////////////// REDEFINIR SENHA MODAL ///////////////////// */}

      {modalRedefinirSenha && (
        <>
          <Modal keepMounted open={modalRedefinirSenha}>
            <Box className="modais d-flex justify-content-center align-items-center">
              <CloseIcon
                className="modalCloseIcon"
                onClick={() => {
                  setModalRedefinirSenha(false);
                  setRedefinirSenha({});
                  navigate("/");
                }}
                style={{ fontSize: 30 }}
              />

              <div className="primeiroAcessoConteudo">
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-12  d-flex justify-content-center align-items-center">
                    <div className="col-12">
                      <h3>Redefinição de senha</h3>
                      <div className="mb-5">
                        <div className="esqueciParagrafo mt-3">
                          Cria sua nova senha, contendo
                        </div>
                        <div className="esqueciParagrafo">
                          - No mínimo 8 caracteres;
                        </div>
                        <div className="esqueciParagrafo">- Com letras;</div>
                        <div className="esqueciParagrafo">
                          - Pelo menos 1 caractere especial (!@#$%).
                        </div>
                      </div>
                      <div className="my-3">
                        <TextField
                          id="demo-helper-text-misaligned"
                          label="Criar Senha"
                          className="inputLogin"
                          value={redefinirSenha.senha}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            e.persist();
                            setRedefinirSenha((prevState) => ({
                              ...prevState,
                              senha: e.target.value,
                            }));
                          }}
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="my-3">
                        <TextField
                          id="demo-helper-text-misaligned"
                          label="Repetir Senha"
                          className="inputLogin"
                          value={redefinirSenha.confirmarSenha}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            e.persist();
                            setRedefinirSenha((prevState) => ({
                              ...prevState,
                              confirmarSenha: e.target.value,
                            }));
                          }}
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="mt-4 d-flex">
                        <button
                          className="btn-modal"
                          onClick={fnRedefinirSenha}
                        >
                          SALVAR SENHA E ACESSAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
