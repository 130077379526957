import React, { useState } from "react";
import "./index.css";
import LogoMetilife from "../../assets/images/pngs/logoMetilife.png";
import { BiUser } from "react-icons/bi";
import { AiOutlineBook } from "react-icons/ai";

import { BsGraphUp } from "react-icons/bs";
import { ImExit } from "react-icons/im";
import { BsPeople } from "react-icons/bs";
import { FcMenu } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { RiAdminFill } from "react-icons/ri";

import BtSemDrop from "./botoesMenu/btSemDrop";
import BtComDrop from "./botoesMenu/btComDrop";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../services/storage";
import { useEffect } from "react";
import { MdCancel, MdOutlinePayment, MdOutlineUndo } from "react-icons/md";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdOutlineLocalShipping } from "react-icons/md";

export default function Menu() {
  const [menuAbrir, setMenuAbrir] = useState(true);
  const [menuSelecionado, setMenuSelecionado] = useState("");
  const navigate = useNavigate();

  const pathname = () => {
    return window.location.pathname;
  };
  useEffect(() => {
    pathname();
  }, [navigate, menuAbrir]);

  return (
    <>
      <div className="col-12">
        <div
          className={"fundoMenu " + (menuAbrir ? "mostrarNavBar" : "")}
        ></div>
        <span
          className="pointer m-1"
          style={{ color: "#1C2D4C ", fontSize: "18px" }}
          onClick={() => {
            !menuAbrir ? setMenuAbrir(true) : setMenuAbrir(false);
          }}
        >
          {!menuAbrir ? <FcMenu /> : <IoMdClose />}
        </span>
        <div className={"navBar " + (menuAbrir ? "mostrarNavBar" : "")}>
          <Link to="/home">
            <div className="d-flex justify-content-center align-items-center divLogo">
              <img
                className={
                  "imgMenu m-3 pointer " + (menuAbrir ? "" : "imgMenuReducao")
                }
                src={LogoMetilife}
                alt="logo da devpartner"
              />
            </div>
          </Link>

          <BtComDrop
            logo={<BiUser />}
            titulo={"Gestão de Usuario"}
            botoes={[
              <Link to="/gestao-usuario/administrador">
                <BtSemDrop
                  logo={<RiAdminFill />}
                  rota={"/gestao-usuario/administrador"}
                  titulo={"Administradores"}
                />
              </Link>,
              <Link to="/gestao-usuario/cliente">
                <BtSemDrop
                  logo={<BsPeople />}
                  rota={"/gestao-usuario/cliente"}
                  titulo={"Cliente"}
                />
              </Link>,
            ]}
          />

          <Link to="/gestao-venda">
            <BtSemDrop
              logo={<AiOutlineBook />}
              titulo={"Gestão de vendas"}
              rota={"/gestao-venda"}
            />
          </Link>

          <BtComDrop
            logo={<MdOutlineIntegrationInstructions />}
            titulo={"Integrações"}
            botoes={[
              <Link to="/integracoes/pagamento">
                <BtSemDrop
                  logo={<MdOutlinePayment />}
                  rota={"/integracoes/pagamento"}
                  titulo={"Pagamento"}
                />
              </Link>,
              <Link to="/integracoes/cancelamento">
              <BtSemDrop
                logo={<MdCancel />}
                rota={"/integracoes/cancelamento"}
                titulo={"Cancelamento"}
              />
            </Link>,
              <Link to="/integracoes/remessa">
                <BtSemDrop
                  logo={<MdOutlineLocalShipping />}
                  rota={"/integracoes/remessa"}
                  titulo={"Remessa"}
                />
              </Link>,
            ]}
          />

          <Link
            to="/"
            onClick={() => {
              storage.del("TOKEN");
            }}
          >
            <BtSemDrop
              className="btMenuSair"
              logo={<ImExit />}
              titulo={"Sair"}
            />
          </Link>
        </div>
      </div>
    </>
  );
}
