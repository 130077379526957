import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import AdminPage from "../layouts/AdminPage";
import Login from "../Pages/Login";

import storage from "../services/storage";
import "../../src/index.css";
import Menu from "../Components/Menu";
import Home from "../Pages/Home/inde";

// import Dashboard from "../Pages/Dashboard";

import GestaoCliente from "../Pages/Gestao_usuario/Cliente";
import GestaoClienteDet from "../Pages/Gestao_usuario/Cliente/ClienteDetalhe";
import GestaoAdministradorNovo from "../Pages/Gestao_usuario/Administrador/AdministradorNovo";
import GestaoAdministradorDetalhe from "../Pages/Gestao_usuario/Administrador/AdministradorDetalhe";
import GestaoAdministrador from "../Pages/Gestao_usuario/Administrador";
import Footer from "../Pages/Footer/inde";
import GestaoDeVenda from "../Pages/Getao_venda";
import Pagamento from "../Pages/Pagamento";
import Remessa from "../Pages/Remessa";
import Cancelamento from "../Pages/Cancelamento";

const PrivateRoutes = ({ children, redirectTo }) => {
  const autenticado = storage.get("TOKEN") != null;
  return autenticado ? (
    <>
      <Menu />
      {children}
      <Footer/>
    </>
  ) : (
    <Navigate to={redirectTo} />
  );
};
export default function Rotas() {
  return (
    <>
      <div className="menuRotas">
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route exact path="/redefinir-senha" element={<Login />}/>
          <Route path="/home" element={<PrivateRoutes redirectTo="/"> <Home />  </PrivateRoutes>}/>
          <Route path="/gestao-venda" element={<PrivateRoutes redirectTo="/"> <GestaoDeVenda />  </PrivateRoutes>} />
          <Route path="/gestao-usuario/administrador" element={<PrivateRoutes redirectTo="/"> <GestaoAdministrador /> </PrivateRoutes>} />
          <Route path="/gestao-usuario/administrador/novo" element={<PrivateRoutes redirectTo="/"><GestaoAdministradorNovo />  </PrivateRoutes>} />
          <Route path="/gestao-usuario/administrador/detalhe/:id" element={<PrivateRoutes redirectTo="/"> <GestaoAdministradorDetalhe /></PrivateRoutes>} />
          <Route path="/gestao-usuario/cliente" element={<PrivateRoutes redirectTo="/"> <GestaoCliente />  </PrivateRoutes>} />
          <Route path="/gestao-usuario/cliente/detalhe/:id" element={<PrivateRoutes redirectTo="/"> <GestaoClienteDet />  </PrivateRoutes>} />
          <Route path="/integracoes/pagamento" element={<PrivateRoutes redirectTo="/"> <Pagamento />  </PrivateRoutes>} />  
          <Route path="/integracoes/cancelamento" element={<PrivateRoutes redirectTo="/"> <Cancelamento />  </PrivateRoutes>} /> 
          <Route path="/integracoes/remessa" element={<PrivateRoutes redirectTo="/"> <Remessa />  </PrivateRoutes>} />  
        </Routes>
      </div>
    </>
  );
}
