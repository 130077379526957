import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";

import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ApiServices from "../../../../services/apiServices";
// import Default from "../../../assets/images/default.png";
import Mask from "../../../../services/mask";
import { toast } from "react-toastify";
import { useLoad } from "../../../../context";
import moment from "moment/moment";
import storage from "../../../../services/storage";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

export default function GestaoClienteDet() {
  const [clienteDet, setClienteDet] = useState([{}]);

  const { load, setLoad } = useLoad();
  const params = useParams();
  const navigate = useNavigate();

  async function fnDetalheCliente() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("participante/" + params.id);

      response.data.dataCadastro = moment(response.data.dataCadastro).format(
        "L"
      );

      if (response.statusCode == 200) {
        setClienteDet(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnDetalheCliente();
  }, []);

  return (
    <div className="row ">
      <h2 className="text-start titulo ">
        GESTÃO DE <strong>CLIENTES</strong>
      </h2>
      <span className="bordaTexto"></span>

      <div className="row mt-5">
        <span className="admTexto mt-3 mb-3">Detalhe Administrador</span>
        <div className="col-md-3 mt-3">
          <TextField
            InputLabelProps={{ shrink: true }}
            id="demo-helper-text-misaligned"
            label="Nome"
            value={clienteDet.nome || ""}
            disabled
            onChange={(e) => {
              e.persist();
              setClienteDet((prev) => ({
                ...prev,
                nome: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-3 mt-3">
          <TextField
            InputLabelProps={{ shrink: true }}
            id="demo-helper-text-misaligned"
            label="CPF"
            value={Mask.cpf(clienteDet.cpf) || ""}
            disabled
            onChange={(e) => {
              e.persist();
              setClienteDet((prev) => ({
                ...prev,
                cpf: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-3 mt-3">
          <TextField
            InputLabelProps={{ shrink: true }}
            id="demo-helper-text-misaligned"
            label="Plano adquirido"
            value={clienteDet.plano || ""}
            disabled
            onChange={(e) => {
              e.persist();
              setClienteDet((prev) => ({
                ...prev,
                plano: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-3 mt-3">
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-textarea"
            label="Data de cadastro"
            value={clienteDet.dataCadastro || ""}
            disabled
            onChange={(e) => {
              e.persist();
              setClienteDet((prev) => ({
                ...prev,
                dataCadastro: Mask.date(e.target.value),
              }));
            }}
            fullWidth
            size="small"
          />
        </div>

      </div>
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className=" botaoDetalheSecundario"
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  );
}
