import React, { useState } from "react";
import "./index.css";

import { MenuItem, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ApiServices from "../../../../services/apiServices";
import { toast } from "react-toastify";
import { useLoad } from "../../../../context";

import storage from "../../../../services/storage";
import Mask from "../../../../services/mask";
import { useEffect } from "react";

export default function GestaoAdministradorNovo() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const [admNovo, setAdmNovo] = useState([]);
  const params = useParams();
  async function fnAdmNovo() {
    try {
      var objEnvio = {
        nome: admNovo.nome,
        cpf: Mask.unMask(admNovo.cpf),
        email: admNovo.email,
        status: admNovo.status,
        senha: admNovo.senha,
        confirmarSenha: admNovo.confirmarSenha,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`administrador`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Administrador cadastrado com Sucesso");
        navigate("/gestao-usuario/administrador");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
 
  const novoAdministrador = (event) => {
    event.preventDefault();
    if (admNovo.senha != admNovo.confirmarSenha) {
      toast.warn("A senha não confere. Confirme a mesma senha.");
    } else {
      fnAdmNovo();
    }
  };

  return (
    <div className="row ">
      <h2 className="text-start titulo ">
        GESTÃO DE <strong>ADMINISTRADORES</strong>
      </h2>
      <span className="bordaTexto"></span>

      <div className="row mt-5">
        <span className="admTexto mt-3 mb-3 ">Novo Administrador</span>
        <div className="col-md-4 mt-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="Nome"
            value={admNovo.nome}
            onChange={(e) => {
              e.persist();
              setAdmNovo((prev) => ({
                ...prev,
                nome: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-4 mt-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="CPF"
            value={admNovo.cpf}
            onChange={(e) => {
              e.persist();
              setAdmNovo((prev) => ({
                ...prev,
                cpf: Mask.cpf(e.target.value),
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-4 mt-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="E-mail"
            value={admNovo.email}
            onChange={(e) => {
              e.persist();
              setAdmNovo((prev) => ({
                ...prev,
                email: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
      </div>

      <div className="row">
        {/* <div className="col-md-4 mt-3">
          <TextField
            id="outlined-select-currency-native"
            select
            label="Status"
            value={admNovo.status || ""} 
            onChange={(e) => {
              setAdmNovo((prev) => ({
                ...prev,
                status: e.target.value === "true", 
              }));
            }}
            fullWidth
            size="small"
          >
            <MenuItem value={"true"}>Ativo</MenuItem>
            <MenuItem value={"false"}>Inativo</MenuItem>
          </TextField>
        </div> */}
        <div className="col-md-4 mt-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="Senha"
            value={admNovo.senha}
            onChange={(e) => {
              e.persist();
              setAdmNovo((prev) => ({
                ...prev,
                senha: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="col-md-4 mt-3">
          <TextField
            id="demo-helper-text-misaligned"
            label="Confirmar Senha"
            value={admNovo.confirmarSenha}
            onChange={(e) => {
              e.persist();
              setAdmNovo((prev) => ({
                ...prev,
                confirmarSenha: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          />
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className=" botaoDetalheSecundario"
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button className="botaoDetalhe" onClick={novoAdministrador}>
            SALVAR
          </button>
        </div>
      </div>
    </div>
  );
}
