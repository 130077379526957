import React, { useEffect, useState } from "react";
import LogoMetilife from "../../assets/images/pngs/logoMetilife.png";

import "./index.css";

export default function Home() {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
  <div>
    <img
      src={LogoMetilife}
      style={{ width: "506px", height: "108px" }}
    />
  </div>
</div>

  
  );
}
