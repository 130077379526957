import React, {useEffect, useState} from "react";
import {useNavigate } from "react-router";
import "./index.css"; 

export default function BtSemDrop(props) {
  const [info, setInfo] = useState({titulo: props.titulo, logo: props?.logo, rota:props?.rota, menuAberto: props.menuAbrir});


  const navigate = useNavigate();

  const pathname = () => {
    return window.location.pathname;
  };
  useEffect(() => {
    pathname();
  }, [navigate]);
  
  return (
    <>
      <div className={pathname().includes(info.rota) ?  "btMenuSemDropSelecionado": "btMenuSemDrop" }>
        <div className={pathname().includes(info.rota) ?  "logoMenuSemDropSelecionado" : "logoMenuSemDrop"}>{info?.logo}</div>
        <div className={pathname().includes(info.rota) ?  "tituloMenuSemDropSelecionado" : "tituloMenuSemDrop"}>{info.titulo}</div>
      </div>
    </>
  );
}
