import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoad } from "../../context";
import ApiServices from "../../services/apiServices";
import TabelaSemPaginacaoDaApi from "../../Components/Tabela/tabelaSemPaginacaoApi";
import TabelaSemPesquisa from "../../Components/Tabela/tabelaSemPesquisa";
import moment from "moment";
import Mask from "../../services/mask";
import FileSaver from "file-saver";
import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Remessa() {
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [listaRemessa, setListaRemessa] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [modal, setModal] = useState(false);
  const [login, setLogin] = useState({ user: "", pass: "" });
  const navigate = useNavigate();
  const colunas = [
    {
      accessorKey: "sequencial",
      header: "ID",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "reqNome",
      header: "Arquivo Enviado",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "reqDataHora",
      header: "Data / Hora",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "reqStatus.nome",
      header: "Status do envio",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (value) => {
        return (
          <>
            {(value.row.original.reqStatus?.remessaStatusId ==
              "credenciais-invalidas" ||
              value.row.original.reqStatus?.remessaStatusId ===
                "erro-inesperado") &&
              "❌"}
            {value.row.original.reqStatus?.remessaStatusId ==
              "envio-pendente" && <span style={{ fontSize: 20 }}>⏳</span>}
            {value.row.original.reqStatus?.remessaStatusId ==
              "envio-com-sucesso" && "✅"}

            {value.row.original.reqStatus.nome}
          </>
        );
      },
    },
    {
      accessorKey: "resStatus.nome",
      header: "Status do retorno",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (value) => {
        return (
          <>
            {(value.row.original.resStatus?.remessaStatusId ==
              "credenciais-invalidas" ||
              value.row.original.resStatus?.remessaStatusId ==
                "retorno-com-erro" ||
              value.row.original.resStatus?.remessaStatusId ==
                "erro-inesperado") &&
              "❌"}
            {value.row.original.resStatus?.remessaStatusId ==
              "retorno-pendente" && <span style={{ fontSize: 20 }}>⏳</span>}
            {value.row.original.resStatus?.remessaStatusId ==
              "retorno-com-sucesso" && "✅"}

            {value.row.original?.resStatus?.nome}
          </>
        );
      },
    },

    {
      id: "mrt-custom-actions",
      header: "Ação",

      accessor: (row) => row,
      Cell: ({ row }) => (
        <>
          <FormControl fullWidth>
            <Select
              value={"0"}
              onChange={(e) => {
                "0";
              }}
            >
              <MenuItem value="0">Selecione</MenuItem>

              {(row.original?.reqStatus.remessaStatusId ==
                "credenciais-invalidas" ||
                row.original?.resStatus?.remessaStatusId ==
                  "credenciais-invalidas") && (
                <MenuItem
                  value="1"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  Trocar credenciais
                </MenuItem>
              )}

              {row.original?.reqNome && (
                <MenuItem
                  value="2"
                  onClick={() => {
                    fnBaixarRequisicaoJson(row.original?.remessaId);
                  }}
                >
                  Baixar remessa (JSON)
                </MenuItem>
              )}
              {row.original?.reqNome && (
                <MenuItem
                  value="3"
                  onClick={() => {
                    fnBaixarRequisicaotxt(row.original?.remessaId);
                  }}
                >
                  Baixar remessa (TXT)
                </MenuItem>
              )}
              {row.original?.resNome && (
                <MenuItem
                  value="4"
                  onClick={() => {
                    fnBaixarRespostaJson(row.original?.remessaId);
                  }}
                >
                  Baixar retorno(JSON)
                </MenuItem>
              )}
              {row.original?.resNome && (
                <MenuItem
                  value="5"
                  onClick={() => {
                    fnBaixarRespostaTxt(row.original?.remessaId);
                  }}
                >
                  Baixar retorno(TXT)
                </MenuItem>
              )}

              {(row.original?.reqStatus.remessaStatusId == "erro-inesperado" ||
                row.original?.reqStatus.remessaStatusId ==
                  "credenciais-invalidas" ||
                row.original?.reqStatus.remessaStatusId ==
                  "envio-pendente") && (
                <MenuItem
                  value="6"
                  onClick={() => {
                    fnReenviarRemessa(row.original?.remessaId);
                  }}
                >
                  Reenviar
                </MenuItem>
              )}

              {(row.original?.resStatus?.remessaStatusId == "erro-inesperado" ||
                row.original?.resStatus?.remessaStatusId ==
                  "credenciais-invalidas" ||
                row.original?.resStatus?.remessaStatusId ==
                  "retorno-com-erro" ||
                row.original?.resStatus?.remessaStatusId ==
                  "retorno-pendente") && (
                <MenuItem
                  value="7"
                  onClick={() => {
                    fnReprocessarRetorno(row.original?.remessaId);
                  }}
                >
                  Reprocessar retorno
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>
      ),

      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
      size: 150,
    },
  ];

  async function fnListarRemessa() {
    const parametros = {
      page: paginacao.pageIndex + 1,
      pageSize: paginacao.pageSize || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`remessa?${queryString}`);

      response.data.items.forEach(function (item) {
        item.reqDataHora = moment(item.reqDataHora).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });
      if (response.statusCode == 200) {
        setListaRemessa(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnTrocarCredenciais() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "go-anywhere/trocar-credenciais",
        login
      );

      toast.success(response.data);

      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnBaixarRequisicaoJson(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportarComNome(
        `remessa/${remessaId}/baixar-requisicao-json`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnBaixarRequisicaotxt(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportarComNome(
        `remessa/${remessaId}/baixar-requisicao-txt`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnBaixarRespostaJson(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportarComNome(
        `remessa/${remessaId}/baixar-resposta-json`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnBaixarRespostaTxt(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportarComNome(
        `remessa/${remessaId}/baixar-resposta-txt`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnReenviarRemessa(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        `remessa/${remessaId}/reenviar`
      );
      toast.success(response.data);
      fnListarRemessa();
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function fnReprocessarRetorno(remessaId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        `remessa/${remessaId}/reprocessar-retorno`
      );
      toast.success(response.data);
      fnListarRemessa();
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarRemessa();
  }, [paginacao.pageIndex, paginacao.pageSize]);
  return (
    <div className="row">
      <h2 className="text-start">
        Acompanhamento<strong> Remessa e Retorno</strong>
      </h2>
      <span className="bordaTexto mb-5"></span>

      <TabelaSemPesquisa
        dados={listaRemessa.items || []}
        colunas={colunas}
        itens={listaRemessa.totalCount}
        paginacao={paginacao}
        setPaginacao={setPaginacao}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        filtroColuna={false}
        paginacaoPelaApi={true}
      />

      {modal && (
        <Modal keepMounted open={modal}>
          <Box className="modais d-flex justify-content-center align-items-center">
            <CloseIcon
              className="modalCloseIcon"
              onClick={() => {
                setModal(false);
              }}
              style={{ fontSize: 30 }}
            />

            <div className="d-flex flex-column pt-3">
              <div>Cadastre um login de acesso!</div>

              <TextField
                InputLabelProps={{ shrink: true }}
                id="demo-helper-text-misaligned"
                label="Login"
                value={login.user}
                onChange={(e) => {
                  e.persist();
                  setLogin((prev) => ({ ...prev, user: e.target.value }));
                }}
                fullWidth
                className="my-4"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                id="demo-helper-text-misaligned"
                label="Senha"
                value={login.pass}
                onChange={(e) => {
                  e.persist();
                  setLogin((prev) => ({ ...prev, pass: e.target.value }));
                }}
                fullWidth
              />
              <button
                className="botaoSecundario mt-3"
                onClick={() => {
                  fnTrocarCredenciais();
                  setModal(false);
                }}
              >
                Enviar
              </button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
