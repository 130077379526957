import globals from "../globals";
import Storage from "./storage";

function getToken() {
  let userCredencial = Storage.get("TOKEN");
  return userCredencial;
}

const ApiServices = {
  apiLoginBase: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Accept: "*/*",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },
  apiPostTokenBearer: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },

  apiGetTokenBearer: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },

  apiLogin: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiGetWithToken: (parameter = undefined, token = undefined) => {
    if (parameter && token) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },

  apiGet: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },

  apiGetExportar: (parameter) => {
    return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json", // Adicione o cabeçalho Content-Type
      },
      responseType: "blob",
    }).then((res) => res.blob());
  },

  apiGetExportarComNome: async (parameter) => {
    try {
      const response = await fetch(
        `${globals.REACT_APP_APIMETLIFE}${parameter}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        return await response.json();
      }
      const blob = await response.blob();

      // Função para criar um link temporário e simular um clique para baixar o arquivo
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      const contentDispositionHeader = response.headers.get(
        "content-disposition"
      );
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const fileNameMatch = fileNameRegex.exec(contentDispositionHeader);
      const fileName =
        fileNameMatch && fileNameMatch[1]
          ? fileNameMatch[1].replace(/['"]/g, "")
          : "file";
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Não retorna nada aqui, pois o download é a ação principal desta função
    } catch (error) {
      console.error("Erro ao exportar o arquivo:", error);
      throw error; // Rejoga o erro para que seja tratado fora desta função, se necessário
    }
  },

  apiPostExportar: (parameter = undefined, obj = undefined) => {
    return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: "blob",
    }).then((res) => res.blob()); // Obtenha o blob diretamente da resposta
  },

  apiPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "multipart/form-data",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },
  apiGetEndereco: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${globals.REACT_APP_ENDERECO}${parameter}`, {
        method: "GET",
      }).then((res) => res.json());
    } else {
    }
  },
  apiPut: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
      // .then(res => res);
    } else {
    }
  },
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  apiPostWithToken: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      if (captcha == undefined || captcha == "") {
        return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      } else {
        return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            captcha: captcha,
            Authorization: `Bearer ${getToken()}`,
            //'key':RECAPTCHAKEY
          },
          body: JSON.stringify(obj),
        }).then((res) => res.json());
      }
    } else {
    }
  },

  apiPostWithTokenNotaFiscal: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "multipart/form-data",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },

  apiPatch: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiPutReturnJson: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  apiDel: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => res);
    } else {
    }
  },

  apiPostWithTokenPremiacao: (
    parameter = undefined,
    page = 1,
    per_page = 5,
    pesquisa = ""
  ) => {
    if (parameter) {
      var captcha = Storage.get("captcha");
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          captcha: captcha,
          Authorization: `Bearer ${getToken()}`,
          //'key':RECAPTCHAKEY
        },
        body: JSON.stringify({
          page: page,
          per_page: per_page,
          pesquisa: pesquisa,
        }),
      }).then((res) => res.json());
    }
  },

  apiGetWithIdAndToken: (parameter = undefined, id = undefined) => {
    if (parameter) {
      return fetch(`${globals.REACT_APP_APIMETLIFE}${parameter}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },
};

export default ApiServices;
