import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoad } from "../../../context";
import ApiServices from "../../../services/apiServices";
import TabelaSemPaginacaoDaApi from "../../../Components/Tabela/tabelaSemPaginacaoApi";
import TabelaSemPesquisa from "../../../Components/Tabela/tabelaSemPesquisa";
import moment from "moment";
import Mask from "../../../services/mask";
import FileSaver from "file-saver";
import { Box, IconButton } from "@mui/material";
import { FiEdit } from "react-icons/fi";
export default function GestaoAdministrador() {
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [informacoes, setInformacoes] = useState({
    data: {
      items: [],
    },
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const navigate = useNavigate();

  async function fnAdministradorList() {
    try {
      setLoad(true);
      const filters = {
        nome: columnFilters.find((filter) => filter.id === "nome")?.value || "",
        cpf: columnFilters.find((filter) => filter.id === "cpf")?.value || "",
        status:
          columnFilters.find((filter) => filter.id === "status")?.value || "",
        dataCadastro:
          columnFilters.find((filter) => filter.id === "dataCadastro")?.value ||
          "",
        email:
          columnFilters.find((filter) => filter.id === "email")?.value || "",
      };
      const filterParams = new URLSearchParams(filters).toString();

      let response = await ApiServices.apiGet(
        `administrador?page=${paginacao.pageIndex + 1}&pageSize=${
          paginacao.pageSize
        }&${filterParams}`
      );
      response.data.items.forEach(function (item) {
        item.dataCadastro = moment(item.dataCadastro).format("DD/MM/YYYY");
        item.cpf = Mask.cpf(item.cpf);
      });
      if (response.statusCode == 200) {
        setInformacoes(response);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "cpf",
      header: "CPF",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "email",
      header: "E-mail",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "dataCadastro",
      header: "Data de cadastro",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      id: "mrt-custom-actions",
      header: "Ação",

      accessor: (row) => row,
      Cell: ({ row }) => (
        <Box>
          <IconButton
            onClick={() => { 
              navigate(
                "/gestao-usuario/administrador/detalhe/" + row.original.userId
              );
            }}
            className="iconDet"
          >
            <FiEdit />
          </IconButton>
        </Box>
      ),

      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function exportarAdm() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportar(
        "administrador/exportar"
      );
      FileSaver.saveAs(response, "MetLife - Relatório administrador.xlsx");
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnAdministradorList();
  }, [columnFilters, paginacao]);
  return (
    <div className="row">
      <h2 className="text-start">
        GESTÃO DE <strong>ADMINISTRADOR</strong>
      </h2>
      <span className="bordaTexto"></span>

      <div className="row d-flex my-4 ">
        <div className="col-md-3 mt-4">
          <button
            className="botaoPrincipal"
            onClick={() => navigate(`/gestao-usuario/administrador/novo`)}
          >
            NOVO ADMINISTRADOR
          </button>
        </div>
        <div className="col-md-3 mt-4">
          <button className="botaoSecundario" onClick={exportarAdm}>
            LOG ACESSO ADMINISTRADOR
          </button>
        </div>
      </div>
      <span className="admTexto mt-3 mb-3 ">Administradores cadastrados</span>

      <TabelaSemPesquisa
        dados={informacoes?.data?.items}
        colunas={colunas}
        itens={informacoes?.data?.totalCount}
        paginacao={paginacao}
        setPaginacao={setPaginacao}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        paginacaoPelaApi={true}
      />
    </div>
  );
}
