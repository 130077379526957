import React from "react";
import MaterialReactTable from "material-react-table";
import { Box, darken } from "@mui/material";
import { IconButton } from "@mui/material";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { FiEdit } from "react-icons/fi";

export default function TabelaSemPesquisa({
  colunas,
  dados,
  itens,
  setPaginacao,
  setColumnFilters,
  paginacao,
  columnFilters,
  filtroColuna = true,
  paginacaoPelaApi = false,
}) {
  return (
    <div>
      <MaterialReactTable
        // enablePagination={false}
        columns={colunas} 
        data={dados}
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
        localization={MRT_Localization_PT}
        manualPagination={paginacaoPelaApi}
        rowCount={itens}
        onPaginationChange={setPaginacao}
        enableColumnFilters={filtroColuna}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        state={{
          pagination: paginacao,
          columnFilters: columnFilters,
        }}
      />
    </div>
  );
}
