import React, {useEffect, useState} from "react";
import "./index.css";
import {BsChevronDown} from "react-icons/bs";
import { useNavigate } from "react-router";

export default function BtComDrop(props, i) {
  const [info, setInfo] = useState({titulo: props.titulo, logo: props.logo, botoes: props.botoes});
  const [abreDrop, setAbreDrop] = useState(false);
  const navigate = useNavigate();

  const pathname = () => {
    return window.location.pathname;
  };
  useEffect(() => {
    pathname();
  }, [navigate]);
  return (
    <>
      <div className="btMenu"  onClick={()=>{ abreDrop ? setAbreDrop(false): setAbreDrop(true)}}>
        <div className="logoMenu">{info?.logo}</div>
        <div className="tituloMenu ">{info.titulo}</div>
        <BsChevronDown style={{position: "absolute", left: 250}} />
      </div>
      {abreDrop && <div className="subMenu" >{info.botoes.map((e, i) => <div key={i}>{e}</div>)}</div>}
    </>
  );
}
