import React, { useEffect, useState } from "react";
import LogoMetilife from "../../assets/images/pngs/logoMetilife.png";

import "./index.css";

export default function Footer() {
  return (
    <>
      <div className="divFooterEspaco"></div>
      <div className="row divFooter">
        <div className="col-md-12 text-center">
          <span className="textoFooter">
            © 2023 Metropolitan Life Seguros e Previdência Privada S.A - Todos
            os direitos reservados.
          </span>
        </div>
      </div>
    </>
  );
}
