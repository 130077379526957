import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ApiServices from "../../services/apiServices";
import { FormControl } from "@mui/material";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

export default function Relatorios() {
  const [exportarSelecionado, setExportarSelecionado] = useState("logs");
  const [loading, setLoading] = useState(false);

  const exportar = async () => {
    try {
      setLoading(true);

      let endpoint;

      if (exportarSelecionado == "logs") {
        endpoint = "contrato/log/todos/exportar";
      } else {
        endpoint = "contrato/log/finalizados/exportar";
      }

      const response = await ApiServices.apiGetExportar(endpoint);
      FileSaver.saveAs(
        response,
        exportarSelecionado == "logs"
          ? "MetLife - Relatório de Logs.xlsx"
          : "MetLife - Relatório de Vendas.xlsx"
      );
      console.log(response)
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Erro ao exportar relatório", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <h2 className="text-start">
        GESTÃO DE <strong>VENDAS</strong>
      </h2>
      <span className="bordaTexto"></span>
      <div className="row mt-5">
        <div className="col-md-4">
          <FormControl fullWidth>
            <Select
              value={exportarSelecionado}
              onChange={(e) => {
                setExportarSelecionado(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Bandeira" }}
            >
              <MenuItem value="logs">Relatórios de logs</MenuItem>
              <MenuItem value="vendas">
                Relatórios de vendas realizadas
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-3">
          <button
            className="botaoSecundario"
            onClick={exportar}
            disabled={!exportarSelecionado}
          >
            EXPORTAR
          </button>
        </div>
      </div>
    </div>
  );
}
