import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";

import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ApiServices from "../../../../services/apiServices";
import Default from "../../../../assets/images/default.png";
import Mask from "../../../../services/mask";
import { toast } from "react-toastify";
import { useLoad } from "../../../../context";
import moment from "moment/moment";
import storage from "../../../../services/storage";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

export default function GestaoAdministradorDetalhe() {
  const [admDet, setAdmDet] = useState([{}]);

  const { load, setLoad } = useLoad();
  const params = useParams();
  const navigate = useNavigate();

  async function fnAdmDetalhe() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("administrador/" + params.id);

      if (response.statusCode == 200) {
        setAdmDet(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }


  async function fnAlterarAdm() {
    try {
      setLoad(true);
      var objEnvio = {
        nome: admDet.nome,
        cpf:  Mask.unMask(admDet.cpf),
        email: admDet.email,
        senha: admDet.senha,
        confirmarSenha: admDet.confirmarSenha,
      };

      let response = await ApiServices.apiPut("administrador/" + params.id, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Administrador alterado com Sucesso");
        navigate("/gestao-usuario/administrador");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
      toast.error(err);
    } finally {
      setLoad(false);
    }
  }
  const alterarAdm = (event) => {
    event.preventDefault();
    if (admDet.senha != admDet.confirmarSenha) {
      toast.warn("A senha não confere. Confirme a mesma senha.");
    } else {
      fnAlterarAdm();
    }
   
  };
  useEffect(() => {
    fnAdmDetalhe();
  }, []);

  return (
    <div className="row ">
    <h2 className="text-start titulo ">
      GESTÃO DE <strong>ADMINISTRADORES</strong>
    </h2>
    <span className="bordaTexto"></span>

    <div className="row mt-5">
      <span className="admTexto mt-3 mb-3">Detalhe Administrador</span>
      <div className="col-md-4 mt-3">
        <TextField
         InputLabelProps={{ shrink: true }}
          id="demo-helper-text-misaligned"
          label="Nome"
          value={admDet.nome || ""}
          onChange={(e) => {
            e.persist();
            setAdmDet((prev) => ({
              ...prev,
              nome: e.target.value,
            }));
          }}
          fullWidth
          size="small"
        />
      </div>
      <div className="col-md-4 mt-3">
        <TextField
         InputLabelProps={{ shrink: true }}
          id="demo-helper-text-misaligned"
          label="CPF"
          value={Mask.cpf(admDet.cpf)|| ""}
          onChange={(e) => {
            e.persist();
            setAdmDet((prev) => ({
              ...prev,
              cpf: Mask.cpf(e.target.value),
            }));
          }}
          fullWidth
          size="small"
        />
      </div>
      <div className="col-md-4 mt-3">
        <TextField
         InputLabelProps={{ shrink: true }}
          id="demo-helper-text-misaligned"
          label="E-mail"
          value={admDet.email || ""}
          onChange={(e) => {
            e.persist();
            setAdmDet((prev) => ({
              ...prev,
              email: e.target.value,
            }));
          }}
          fullWidth
          size="small"
        />
      </div>
    </div>

    <div className="row">
    {/* <div className="col-md-4 mt-3">
          <TextField
            id="outlined-select-currency-native"
            select
            label="Status"
            InputLabelProps={{ shrink: true }}
            value={admDet.status || ""}
            onChange={(e) => {
              setAdmDet((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
            fullWidth
            size="small"
          >
            <MenuItem value={"Ativo"}>Ativo</MenuItem>
            <MenuItem value={"Inativo"}>Inativo</MenuItem>
          </TextField>
        </div> */}
      <div className="col-md-4 mt-3">
        <TextField
         InputLabelProps={{ shrink: true }}
          id="demo-helper-text-misaligned"
          label="Senha"
          value={admDet.senha || ""}
          onChange={(e) => {
            e.persist();
            setAdmDet((prev) => ({
              ...prev,
              senha: e.target.value,
            }));
          }}
          fullWidth
          size="small"
        />
      </div>
      <div className="col-md-4 mt-3">
        <TextField
         InputLabelProps={{ shrink: true }}
          id="demo-helper-text-misaligned"
          label="Confirmar Senha"
          value={admDet.confirmarSenha || ""}
          onChange={(e) => {
            e.persist();
            setAdmDet((prev) => ({
              ...prev,
              confirmarSenha: e.target.value,
            }));
          }}
          fullWidth
          size="small"
        />
      </div>
    </div>

    <div className="row mt-5 d-flex justify-content-center mb-5">
      <div className="col-md-3 mt-2">
        <button
          className=" botaoDetalheSecundario"
          onClick={() => {
            navigate(-1);
          }}
        >
          VOLTAR
        </button>
      </div>
      <div className="col-md-3 mt-2">
        <button className="botaoDetalhe" onClick={alterarAdm}>
          SALVAR
        </button>
      </div>
    </div>
  </div>
  );
}
