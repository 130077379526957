import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoad } from "../../context";
import ApiServices from "../../services/apiServices";
import TabelaSemPaginacaoDaApi from "../../Components/Tabela/tabelaSemPaginacaoApi";
import TabelaSemPesquisa from "../../Components/Tabela/tabelaSemPesquisa";
import moment from "moment";
import Mask from "../../services/mask";
import FileSaver from "file-saver";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FiEdit } from "react-icons/fi";
export default function Pagamento() {
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [informacoes, setInformacoes] = useState({
    data: {
      items: [],
    },
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [itemSelecionado, setItemSelecionado] = useState([]);
  const colunas = [
    {
      accessorKey: "data",
      header: "Data",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "usuarioCpf",
      header: "Cpf",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "usuarioNome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "planoNome",
      header: "Plano",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: (value) => {
        return (
          <>
            <span
              role="img"
              aria-label={
                value.renderedCellValue === "PaymentConfirmed"
                  ? "PaymentConfirmed"
                  : "(falha na requisição)"
              }
            >
              {value.row.original.sucesso === true
                ? `✅${value.renderedCellValue}`
                : `❌${value.renderedCellValue}`}
            </span>
          </>
        );
      },
    },
    {
      id: "mrt-custom-actions",
      header: "Ação",

      accessor: (row) => row,
      Cell: ({ row }) => (
        <IconButton
          onClick={() => {
            fnPagamentoSelecionado(row.original.contratoPagamentoId);
          }}
          className="iconDet"
        >
          <FiEdit />
        </IconButton>
      ),

      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];
  async function FnPagamento() {
    const parametros = {
      page: paginacao.pageIndex + 1,
      pageSize: paginacao.pageSize || "",
    };
    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `contrato/pagamento/log?${queryString}`
      );
      response.data.items.forEach(function (item) {
        item.data = moment(item.data).format("DD/MM/YYYY H:mm");
        item.usuarioCpf = Mask.cpf(item.usuarioCpf);
      });
      if (response.statusCode == 200) {
        setInformacoes(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPagamentoSelecionado(contratoPagamentoId) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `contrato/pagamento/log/${contratoPagamentoId}`
      );
      if (response.statusCode == 200) {
        setModal(true);
        setItemSelecionado(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function exportarResposta(contratoPagamentoId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportar(
        `contrato/pagamento/log/${contratoPagamentoId}/baixar-resposta`
      );
      FileSaver.saveAs(response, "Resposta.json");
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  async function exportarRequisicao(contratoPagamentoId) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportar(
        `contrato/pagamento/log/${contratoPagamentoId}/baixar-requisicao`
      );
      FileSaver.saveAs(response, "Requisição.json");
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    FnPagamento();
  }, [paginacao.pageIndex, paginacao.pageSize]);

  return (
    <div className="row">
      <h2 className="text-start">
        HISTÓRICO DE
        <strong> PAGAMENTOS</strong>
      </h2>
      <span className="bordaTexto mb-5"></span>
      <TabelaSemPesquisa
        dados={informacoes.items || []}
        colunas={colunas}
        itens={informacoes.totalCount}
        paginacao={paginacao}
        setPaginacao={setPaginacao}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        filtroColuna={false}
        paginacaoPelaApi={true}
      />

      <Modal keepMounted open={modal}>
        <Box className="modais d-flex justify-content-center align-items-center">
          <CloseIcon
            className="modalCloseIcon"
            onClick={() => {
              setModal(false);
              setItemSelecionado(null);
            }}
            style={{ fontSize: 30 }}
          />

          <div className="primeiroAcessoConteudo">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12  d-flex justify-content-center align-items-center">
                <div className="col-12">
                  {itemSelecionado && modal && (
                    <div className="primeiroAcessoConteudo">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-12  d-flex justify-content-center align-items-center">
                          <div className="col-12">
                            <p>
                              <strong>Data:</strong>
                              <>&nbsp;</>
                              {moment(itemSelecionado.data).format(
                                "DD/MM/YYYY H:mm"
                              )}
                            </p>
                            <p>
                              <strong>CPF:</strong>
                              <>&nbsp;</>{" "}
                              {Mask.cpf(itemSelecionado?.usuarioCpf)}
                            </p>
                            <p>
                              <strong>Nome:</strong>
                              <>&nbsp;</> {itemSelecionado?.usuarioNome}
                            </p>

                            <p>
                              <strong>E-mail:</strong> <>&nbsp;</>
                              {itemSelecionado?.usuarioEmail}
                            </p>

                            <p>
                              <strong>Telefone:</strong>
                              <>&nbsp;</>{" "}
                              {Mask.cel(itemSelecionado?.usuarioTelefone)}
                            </p>
                            <p>
                              <strong>Plano:</strong>
                              <>&nbsp;</> {itemSelecionado?.planoNome}
                            </p>

                            <p>
                              <strong>Período:</strong>
                              <>&nbsp;</>
                              {itemSelecionado?.periodoValidade} meses
                            </p>
                            <p>
                              <strong>Premio mensal:</strong>
                              <>&nbsp;</>
                              {itemSelecionado?.periodoCusto?.toLocaleString(
                                "pt-BR",
                                { style: "currency", currency: "BRL" }
                              )}
                            </p>
                            <p>
                              <strong>Status:</strong>
                              <>&nbsp;</>
                              {itemSelecionado?.sucesso === true
                                ? `✅${itemSelecionado?.status}`
                                : `❌${itemSelecionado?.status}`}
                            </p>

                            <button
                              className="botaoSecundario mt-3"
                              onClick={() => {
                                exportarRequisicao(
                                  itemSelecionado?.contratoPagamentoId
                                );
                              }}
                            >
                              EXPORTAR REQUISIÇÃO
                            </button>

                            <button
                              className="botaoSecundario mt-3"
                              onClick={() => {
                                exportarResposta(
                                  itemSelecionado?.contratoPagamentoId
                                );
                              }}
                            >
                              EXPORTAR RESPOSTA
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
