import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoad } from "../../../context";
import ApiServices from "../../../services/apiServices";
import TabelaSemPaginacaoDaApi from "../../../Components/Tabela/tabelaSemPaginacaoApi";
import Tabela from "../../../Components/Tabela";
import TabelaSemPesquisa from "../../../Components/Tabela/tabelaSemPesquisa";
import moment from "moment";
import FileSaver from "file-saver";
import Mask from "../../../services/mask";
import { FiEdit } from "react-icons/fi";
import { Box, IconButton } from "@mui/material";
export default function GestaoCliente() {
  const { load, setLoad } = useLoad();
  const [pesquisar, setPesquisar] = useState("");
  const [cliente, setCliente] = useState([{}]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [informacoes, setInformacoes] = useState({
    data: {
      items: [],
    },
  });
  const [columnFilters, setColumnFilters] = useState([]);

  const navigate = useNavigate();
  async function fnListaCliente() {
    try {
      setLoad(true);

      const filters = {
        nome: columnFilters.find((filter) => filter.id === "nome")?.value || "",
        cpf: columnFilters.find((filter) => filter.id === "cpf")?.value || "",
        plano:
          columnFilters.find((filter) => filter.id === "plano")?.value || "",
        dataCadastro:
          columnFilters.find((filter) => filter.id === "dataCadastro")?.value ||
          "",
        email:
          columnFilters.find((filter) => filter.id === "email")?.value || "",
        telefone:
          columnFilters.find((filter) => filter.id === "telefone")?.value || "",
      };

      const filterParams = new URLSearchParams(filters).toString();

      let response = await ApiServices.apiGet(
        `participante?page=${paginacao.pageIndex + 1}&pageSize=${
          paginacao.pageSize
        }&${filterParams}`
      );

      response.data.items.map((item) => {
        item.dataCadastro = moment(item.dataCadastro).format("DD/MM/YYYY");
        item.cpf = Mask.cpf(item.cpf);
        item.telefone = Mask.cel(item.telefone);
      });

      if (response.statusCode === 200) {
        setInformacoes(response);
        setCliente(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function exportarCliente() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetExportar(
        "participante/exportar"
      );
      FileSaver.saveAs(response, "MetLife - Relatório cliente.xlsx");
      if (response.statusCode != 200) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }

  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "cpf",
      header: "CPF",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "email",
      header: "E-mail",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "telefone",
      header: "Telefone",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "plano",
      header: "Plano adquirido",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "dataCadastro",
      header: "Data de cadastro",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      id: "mrt-custom-actions",
      header: "Ação",

      accessor: (row) => row,
      Cell: ({ row }) => (
        <IconButton
          onClick={() => { 
            navigate("/gestao-usuario/cliente/detalhe/" + row.original.userId);
          }}
          className="iconDet"
        >
          <FiEdit />
        </IconButton>
      ),

      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  useEffect(() => {
    if (!pesquisar) {
      setPesquisar("");
    }
    fnListaCliente();
  }, [pesquisar, paginacao, columnFilters]);

  return (
    <>
      <div className="row">
        <h2 className="text-start">
          GESTÃO DE <strong>CLIENTES</strong>
        </h2>
        <span className="bordaTexto"></span>
        <div className=" d-flex my-4 ">
          <div className="col-md-3 mt-4">
            <button className="botaoSecundario" onClick={exportarCliente}>
              LOG ACESSO CLIENTES
            </button>
          </div>
        </div>
        <span className="admTexto mt-3 mb-3">Clientes cadastrados</span>
        <TabelaSemPesquisa
          dados={informacoes?.data?.items}
          colunas={colunas}
          itens={informacoes?.data?.totalCount}
          paginacao={paginacao}
          setPaginacao={setPaginacao}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          paginacaoPelaApi={true}
        />
      </div>
    </>
  );
}
